// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-bookmarks-js": () => import("./../../../src/templates/bookmarks.js" /* webpackChunkName: "component---src-templates-bookmarks-js" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-cookies-js": () => import("./../../../src/templates/cookies.js" /* webpackChunkName: "component---src-templates-cookies-js" */),
  "component---src-templates-crisis-tsx": () => import("./../../../src/templates/crisis.tsx" /* webpackChunkName: "component---src-templates-crisis-tsx" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-measurement-tsx": () => import("./../../../src/templates/measurement.tsx" /* webpackChunkName: "component---src-templates-measurement-tsx" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

