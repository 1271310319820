import './src/css/main.scss';

const pendoAccountId = process.env.GATSBY_CLINIC_ID;
const pendoAccountName = process.env.GATSBY_PENDO_ACCOUNT_NAME;

function getLocaleFromPathname(pathname) {
  // All pathnames start with '/'
  // The first part of all our pathnames is the locale
  return pathname.slice(1).split('/')[0].toLowerCase();
}

// eslint-disable-next-line import/prefer-default-export
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (
    // Pendo might not be defined — maybe it loaded slowly so it’s not initialized yet
    // or maybe the user has blocked trackers stopping it from loading
    window.pendo &&
    // Pendo gets added to the window before pendo.isReady is defined
    typeof window.pendo.isReady === 'function' &&
    // Make sure Pendo is defined and ready (i.e. fully loaded with an API key)
    window.pendo.isReady() &&
    // If there’s no previous location then the options should be set from the initialization
    prevLocation
  ) {
    const locale = getLocaleFromPathname(location.pathname);
    if (locale && locale !== getLocaleFromPathname(prevLocation.pathname)) {
      window.pendo.updateOptions({
        visitor: { userLanguage: locale },
        account: {
          id: pendoAccountId,
          name: pendoAccountName,
        },
      });
    }
  }

  window.setTimeout(() => {
    if (window.dataLayer) window.dataLayer.push({ event: 'optimize.activate' });
  }, 0);
};
